import styled, {css} from 'styled-components'

import {Inline, tokens} from '@pleo-io/telescope'
import {Warning} from '@pleo-io/telescope-icons'

import {InfoPopover} from './props'

export const Table = styled.table<{$fluid?: boolean}>`
    ${({$fluid}) =>
        !$fluid &&
        css`
            width: 100%;
        `};
    margin: ${tokens.spacing36} 0 ${tokens.spacing36} 0;
    width: 100%;
    border-collapse: collapse;
`

export const TableHead = styled.thead`
    text-align: left;
`

export const TableHeadRow = styled.tr`
    border-bottom: ${tokens.borderStatic};
`

export const TableHeadData = styled.th`
    padding-right: ${tokens.spacing12};
    padding-bottom: ${tokens.spacing12};
    padding-left: ${tokens.spacing12};
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightSemibold};
    font-size: ${tokens.fontMedium};
    border-bottom: ${tokens.borderStatic};

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`

export const TableBody = styled.tbody``

export const TableBodyRow = styled.tr`
    padding-top: ${tokens.spacing4};
    padding-bottom: ${tokens.spacing4};
`

// TODO: Need to double check the color property and its token
export const TableBodyData = styled.td<{nowrap?: boolean}>`
    padding: ${tokens.spacing14};
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontMedium};
    white-space: ${({nowrap}) => (nowrap ? 'nowrap' : 'normal')};
    border-bottom: ${tokens.borderStatic};
    vertical-align: top;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`

export const TablePill = styled.code`
    display: inline-block;
    margin-bottom: ${tokens.spacing4};
    background-color: ${tokens.colorBackgroundPresentationalPink};
`

const PropNameCode = styled.code<{$deprecated?: boolean}>`
    background: ${({$deprecated}) =>
        $deprecated ? tokens.colorBackgroundWarning : tokens.colorBackgroundStaticLoudest};
`

export const TokenName = ({
    name,
    deprecated,
    deprecatedComment,
    description
}: {
    name: string
    deprecated?: boolean
    deprecatedComment?: string
    description?: string
}) => {
    let popover = null
    if (deprecatedComment) {
        popover = (
            <InfoPopover Icon={Warning}>
                <strong>Deprecated</strong>: {deprecatedComment}
            </InfoPopover>
        )
    } else if (description) {
        popover = <InfoPopover>{description}</InfoPopover>
    }
    return (
        <Inline alignItems="center">
            <PropNameCode $deprecated={deprecated}>{name}</PropNameCode>
            {popover}
        </Inline>
    )
}
