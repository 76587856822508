import {graphql, useStaticQuery} from 'gatsby'

import type {FoundationsImagesQuery} from '@/graphql-types'

const useFoundationsImages = () => {
    const data = useStaticQuery<FoundationsImagesQuery>(graphql`
        query FoundationsImages {
            allFile(filter: {absolutePath: {regex: "/src/assets/foundations/"}}) {
                edges {
                    node {
                        relativePath
                        publicURL
                        name
                    }
                }
            }
        }
    `)

    return data.allFile.edges.reduce((acc: Record<string, string>, edge) => {
        acc[edge.node.name] = edge.node.publicURL
        return acc
    }, {})
}

export default useFoundationsImages
