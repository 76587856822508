import {withPrefix} from 'gatsby'
import type {ImgHTMLAttributes} from 'react'
import type React from 'react'

interface StaticImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    src: string
}
// We need to use the withPrefix function to correctly resolve the image
// path for preview deploys.
export const StaticImage: React.FC<StaticImageProps> = ({src, alt, ...props}) => (
    <img src={withPrefix(src)} alt={alt} {...props} />
)
