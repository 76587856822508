import type {ReactNode} from 'react'
import styled from 'styled-components'

import {List, ListIcon, ListItem, px, tokens} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

export const Features = styled(List)`
    margin: ${tokens.spacing18} 0 ${tokens.spacing36} 0;
`

const FeatureContent = styled.div`
    margin-top: ${px(3)};
`

export const Feature = ({children}: {children: ReactNode}) => (
    <FeatureListItem>
        <IconCircle>
            <Icon size={16} as={Check} />
        </IconCircle>
        <FeatureContent>{children}</FeatureContent>
    </FeatureListItem>
)

const IconCircle = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${px(24)};
    height: ${px(24)};
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.circle};
`

const Icon = styled(ListIcon).attrs({mr: 0})`
    margin-bottom: -${tokens.spacing2};
    fill: ${tokens.colorContentStatic};
`

const FeatureListItem = styled(ListItem)`
    display: grid;
    grid-gap: ${tokens.spacing12};
    grid-template-columns: ${tokens.spacing20} 1fr;
`
