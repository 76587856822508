import styled from 'styled-components'

import {px} from '@pleo-io/telescope'
import radius from '@pleo-io/telescope-tokens/dist/radius.json'

import {
    Table,
    TableBody,
    TableBodyData,
    TableBodyRow,
    TableHead,
    TableHeadData,
    TableHeadRow,
    TokenName
} from '@/components/docs/table'
import {backgroundColorExample} from '@/tokens'

const Example = styled.div<{$radius: string; $circle: boolean}>`
    height: ${px(20)};
    width: ${({$circle}) => $circle && px(20)};
    background-color: ${backgroundColorExample};
    border-radius: ${({$radius}) => $radius};
`

export const Radius = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {radius.props.map((token) => (
                    <TableBodyRow key={token.name}>
                        <TableBodyData>
                            <TokenName {...token} />
                        </TableBodyData>
                        <TableBodyData>{token.value}</TableBodyData>
                        <TableBodyData>
                            <Example $radius={token.value} $circle={token.name === 'circle'} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Radius
