import {createContext, useContext, useEffect, useState} from 'react'
import styled from 'styled-components'

import {px, tokens, Tooltip} from '@pleo-io/telescope'
import {__classNameColorSchemeDark} from '@pleo-io/telescope-tokens'

import {useLocalStorage} from '../../hooks/use-local-storage'

export type ThemeName = 'light' | 'dark'

const Context = createContext<{
    theme: ThemeName
    setTheme: (theme: ThemeName) => void
}>({
    theme: 'light',
    setTheme: () => {}
})

const Provider = Context.Provider

export const useThemeSwitcher = () => useContext(Context)

export const ThemeSwitcherProvider = ({children}: any) => {
    const [localStorageTheme, setLocalStorageTheme] = useLocalStorage<ThemeName>(
        localStorageKey,
        'light'
    )
    const [theme, setTheme] = useState<ThemeName>(localStorageTheme)

    const onSetTheme = (newTheme: ThemeName) => {
        setLocalStorageTheme(newTheme)
        setTheme(newTheme)
    }

    return <Provider value={{theme, setTheme: onSetTheme}}>{children}</Provider>
}

const localStorageKey = 'telescope-docs: dark-mode'

const buttonSize = px(36)

const Wrapper = styled.button`
    display: flex;
    align-items: flex-end;
    width: ${buttonSize};
    height: ${buttonSize};
    padding: 0;
    overflow: hidden;
    color: ${tokens.colorContentInteractive};
    background-color: transparent;
    background-color: ${tokens.colorBackgroundInteractive};
    border: none;
    border-radius: ${tokens.arc99999};
    cursor: pointer;
    transition: background-color ${tokens.smooth};
`

const Icons = styled.div<{theme: ThemeName}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    svg {
        flex: 0 0 ${buttonSize};
        height: ${buttonSize};
        padding: ${tokens.spacing8};
    }
`

export const ThemeSwitcher = () => {
    const {theme, setTheme} = useThemeSwitcher()

    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.add(__classNameColorSchemeDark)
        } else {
            document.body.classList.remove(__classNameColorSchemeDark)
        }
    }, [theme])

    const onToggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
    }

    return (
        <Tooltip content="Switch color theme" side="right">
            <Wrapper onClick={onToggleTheme}>
                <Icons theme={theme}>{theme === 'dark' ? <MoonIcon /> : <SunIcon />}</Icons>
            </Wrapper>
        </Tooltip>
    )
}

const SunIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <circle cx="12" cy="12" r="5"></circle>
        <line x1="12" y1="1" x2="12" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="23"></line>
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
        <line x1="1" y1="12" x2="3" y2="12"></line>
        <line x1="21" y1="12" x2="23" y2="12"></line>
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
    </svg>
)

const MoonIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
    </svg>
)
