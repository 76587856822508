import {graphql, useStaticQuery} from 'gatsby'

import type {PatternsImagesQuery} from '@/graphql-types'

const usePatternsImages = () => {
    const data = useStaticQuery<PatternsImagesQuery>(graphql`
        query PatternsImages {
            allFile(filter: {absolutePath: {regex: "/src/assets/patterns/"}}) {
                edges {
                    node {
                        relativePath
                        publicURL
                        name
                    }
                }
            }
        }
    `)

    return data.allFile.edges.reduce((acc: Record<string, string>, edge) => {
        acc[edge.node.name] = edge.node.publicURL
        return acc
    }, {})
}

export default usePatternsImages
