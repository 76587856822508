import {Link as GatsbyLink} from 'gatsby'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {useComponents} from './components-list'

import {LI, UL} from '../layout/mdx-components'

const Anchor = styled(GatsbyLink)`
    color: ${tokens.colorContentInteractiveLink};

    &:hover {
        color: ${tokens.colorContentInteractiveLinkHover};
    }
`

export const RelatedComponents = ({names}: {names: string[]}) => {
    const components = useComponents()
    const filtered = components.filter((component) => names.includes(component.title))

    return (
        <UL>
            {filtered.map((component) => (
                <LI key={component.path}>
                    <Anchor to={component.path}>{component.title}</Anchor>: {component.intro}
                </LI>
            ))}
        </UL>
    )
}
