import {Link} from '@pleo-io/telescope'

import {Blockquote, P} from '../layout/mdx-components'

const featureDescriptions = {
    skeleton: (
        <P>
            Use a "skeleton" version of this component to display a loading state while data is
            being fetched. It provides a visual placeholder, giving users a preview of the UI
            structure before the actual content is loaded. This enhances the user experience by
            offering a more accurate representation of the final layout during data retrieval.
        </P>
    ),
    required: (
        <>
            <P>
                To ensure accessibility, use the <code>isRequired</code> property (not the HTML{' '}
                <code>required</code> property) to{' '}
                <Link href="/patterns/forms/forms.accessibility#required-form-controls">
                    mark a field as required
                </Link>{' '}
                for assistive technologies (such as screen readers).
            </P>
            <Blockquote>
                Visually, you should{' '}
                <Link href="/patterns/forms/forms.about#required-and-optional-fields">
                    mark the minority
                </Link>{' '}
                of fields in a form as "required" or "optional".
            </Blockquote>
        </>
    ),
    invalid: (
        <>
            <P>
                Use the <code>isInvalid</code> property to{' '}
                <Link href="/patterns/forms/forms.accessibility#invalid-form-controls">
                    mark the field as invalid
                </Link>{' '}
                both visually (for sighted users) and for assistive technologies (such as screen
                readers).
            </P>
            <Blockquote>
                Always provide{' '}
                <Link href="/patterns/forms/forms.about#error-messages">a clear error message</Link>{' '}
                when marking a field as invalid.
            </Blockquote>
        </>
    ),
    labels: (
        <P>
            The label of a form element should be clear and concise, ideally under three words. It
            should describe the value of the element and not what the user needs to do in order to
            fill it out properly.
        </P>
    ),
    'never omit the label': (
        <Blockquote>
            <strong>Never omit a label</strong>. Form labels are required for accessibility
            purposes. However, it may be acceptable to{' '}
            <Link href="/patterns/forms/forms.accessibility#hiding-a-label-visually">
                hide the label visually
            </Link>{' '}
            in certain cases.
        </Blockquote>
    ),
    'visually hidden labels': (
        <P>
            Never omit the label completely. In rare cases, you may want to{' '}
            <Link href="/patterns/forms/forms.accessibility#hiding-a-label-visually">
                hide the label visually
            </Link>
            . An example of this could be a search field placed near the top of a page, featuring a
            magnifying glass icon and/or a button labelled “Search”.
        </P>
    ),
    placeholders: (
        <P>
            In general, avoid using placeholder text. Putting instructions or important information
            in placeholder text isn't accessible. As soon as someone starts typing, that text
            vanishes, and people using auto-fill tools can't see it at all.
        </P>
    ),
    'hint text': (
        <P>
            Use hint text to give users clear guidance for data entry. Keep it brief and avoid
            repeating the label or other form details. If it feels redundant, try removing it. We
            keep help text above the input field to have the reading order match the visual order,
            which is needed for screen readers.
        </P>
    ),
    errors: (
        <P>
            Provide clear and helpful error messages that guide users toward a solution. Instead of
            merely stating the problem, ensure the message is specific and actionable. Avoid vague
            or shaming language.
        </P>
    ),
    'help popovers': (
        <P>
            Use help popovers to aid first-time users or to clarify complex concepts. The help
            popover can contain interactive content and may be used to provide links for further
            information.
        </P>
    ),
    disabled: (
        <P>
            Disable a form element to indicate that it’s currently unavailable. This can be used to
            maintain layout consistency and communicate that the element may become available later.
            If the form element will never be available in a given context, leave it out.
        </P>
    ),
    'required or optional': (
        <P>
            Mark the minority of form elements in a form as required or optional. This helps users
            scan the form and understand what is required of them. Type out the word optional or
            required in the label. E.g. "Per purchase limit (optional)". Don't use asterisks or
            other symbols.
        </P>
    ),
    'promote form control': (
        <Blockquote>
            We promote usage of this component in combination with our{' '}
            <Link href="/components/web/form-control/form-control.guidelines">Form control</Link>{' '}
            component to maintain layout consistency and ensure form accessibility. The Form control
            supports advanced use cases such as{' '}
            <Link href="/components/web/form-control/form-control.guidelines#hint-text">
                hint text
            </Link>
            ,{' '}
            <Link href="/components/web/form-control/form-control.guidelines#help-popover">
                help popovers
            </Link>{' '}
            and{' '}
            <Link href="/components/web/form-control/form-control.guidelines#error">
                error messages
            </Link>
            .
        </Blockquote>
    ),
    'usage with form control': (
        <P>
            For advanced use cases and layouts, use this component in combination with our{' '}
            <Link href="/components/web/form-control/form-control.guidelines">Form control</Link>{' '}
            component to maintain layout consistency and ensure form accessibility. The Form control
            supports features such as{' '}
            <Link href="/components/web/form-control/form-control.guidelines#hint-text">
                hint text
            </Link>
            ,{' '}
            <Link href="/components/web/form-control/form-control.guidelines#help-popover">
                help popovers
            </Link>{' '}
            and{' '}
            <Link href="/components/web/form-control/form-control.guidelines#error">
                error messages
            </Link>
            .
        </P>
    ),
    accessibility: (
        <P>
            The implementation of this component has been informed by{' '}
            <Link href="/patterns/forms/forms.accessibility">
                our form accessibility guidelines
            </Link>
            .
        </P>
    ),
    'compatible with form control': (
        <>
            Compatible with our{' '}
            <Link href="/components/web/form-control/form-control.guidelines">Form control</Link>{' '}
            component
        </>
    ),
    'adheres to form accessibility guidelines': (
        <>
            Adheres to{' '}
            <Link href="/patterns/forms/forms.accessibility">
                our form accessibility guidelines
            </Link>
            .
        </>
    ),
    'best practices': (
        <P>
            Refer to our <Link href="/patterns/forms/forms.about">Forms guidelines</Link> for
            general advice on forms structure, validation, and more.
        </P>
    ),
    'usage with formik': (
        <>
            <P>
                For the sake of convenience, we provide a{' '}
                <Link href="https://formik.org">Formik</Link> version of this component. By using{' '}
                <Link href="https://formik.org/docs/api/useField">the `useField` hook</Link> it
                automatically handles value changes, blur events, error messages, and touched state
                for you. We recommend <Link href="https://github.com/jquense/yup">Yup</Link> for
                form validation.
            </P>
            <Blockquote>
                The validation behaviour of the Formik component may not always be inline with those
                in <Link href="/patterns/forms/forms.about#validation">our Form guidelines</Link>,
                so please review before usage.
            </Blockquote>
        </>
    )
}

type FeatureDescriptionProps = {
    feature: keyof typeof featureDescriptions
}

export const FeatureDescription = ({feature}: FeatureDescriptionProps) => (
    <>{featureDescriptions[feature]}</>
)
