import {Link} from '@pleo-io/telescope'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FigmaEmbed = ({height = 450, src}: {height: number; src: string}) => {
    // We temporarily disabled iframes until we implement cookie consent
    // https://linear.app/pleo/issue/DO-3825/make-sure-we-comply-with-cookie-directive-gdpr-long-term-fix

    // we expect an embed src which has a "url" query param that links to component in Figma
    const url = new URL(src)
    const href = url.searchParams.get('url') ?? src
    return (
        <Link href={href} target="_blank" rel="noopener noreferrer">
            View this component in Figma
        </Link>
    )
}
