import styled from 'styled-components'

import {px} from '@pleo-io/telescope'

import {backgroundColorExample} from '@/tokens'

export const Placeholder = styled.div<{width?: number; height?: number}>`
    width: ${({width}) => px(width ?? 300)};
    height: ${({height}) => px(height ?? 40)};
    background-color: ${backgroundColorExample};
`
