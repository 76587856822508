import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'

import type {ComponentsQuery} from '@/graphql-types'
import {sort} from '@/utilities/sort'

import {Card} from './card'
import {Grid} from './list-grid'
import useGraphicElementsImages from './use-graphic-elements-images'

type GraphicElement = {
    path: string
    category: string
    title: string
    intro: string
}

const IllustrationImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
`

const useGraphicElements = () => {
    const data = useStaticQuery<ComponentsQuery>(graphql`
        query GraphicElements {
            components: allSitePage(
                filter: {context: {frontmatter: {section: {eq: "Graphic elements"}}}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            category
                            intro
                            tags
                        }
                    }
                }
            }
        }
    `)

    const graphicElements = data.components.nodes
        .map((graphicElement) => {
            const meta = graphicElement.context.frontmatter
            return {
                path: graphicElement.path,
                title: meta.title,
                category: meta.category,
                intro: meta.intro,
                tags: meta.tags
            }
        })
        .filter(
            (c) =>
                (c.path.match('.overview') || c.path.match('.about')) &&
                !c.path.match('overview.overview')
        )

    return graphicElements.sort(sort((c) => c.title.toLowerCase()))
}

const List = ({graphicElements}: {graphicElements: GraphicElement[]}) => {
    const images = useGraphicElementsImages()

    return (
        <Grid>
            {graphicElements.map(({path, title, intro}) => {
                const image = images[title.toLowerCase()]
                return (
                    <Card
                        key={path}
                        href={path}
                        title={title}
                        illustration={
                            <Card.Illustration>
                                <IllustrationImage alt={`${title} preview`} src={image} />
                            </Card.Illustration>
                        }
                        description={intro}
                    />
                )
            })}
        </Grid>
    )
}

export const GraphicElementsList = () => {
    const graphicElements = useGraphicElements()
    return <List graphicElements={graphicElements} />
}
