import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/color-scheme-light.json'

import {
    Table,
    TableBody,
    TableBodyData,
    TableBodyRow,
    TableHead,
    TableHeadData,
    TableHeadRow,
    TokenName
} from '@/components/docs/table'

const shadows = colorSchemeLight.props.filter(
    (item) => item.category === 'shadow' && item.type !== 'focus'
)

const ShadowExample = styled.div<{$shadow: string}>`
    padding: ${tokens.spacing18};
    border-radius: ${tokens.arc8};
    box-shadow: ${({$shadow}) => $shadow};
`

export const Shadow = () => (
    <Table>
        <TableHead>
            <TableHeadRow>
                <TableHeadData>Token</TableHeadData>
                <TableHeadData>Value</TableHeadData>
                <TableHeadData>Example</TableHeadData>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {shadows.map((token) => (
                <TableBodyRow key={token.name}>
                    <TableBodyData nowrap>
                        <TokenName {...token} />
                    </TableBodyData>
                    <TableBodyData>
                        <Text>{token.value}</Text>
                    </TableBodyData>
                    <TableBodyData>
                        <ShadowExample $shadow={token.value}></ShadowExample>
                    </TableBodyData>
                </TableBodyRow>
            ))}
        </TableBody>
    </Table>
)

export default Shadow
