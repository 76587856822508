import {graphql, useStaticQuery} from 'gatsby'

import type {ComponentImagesQuery} from '@/graphql-types'

const useComponentImages = () => {
    const data = useStaticQuery<ComponentImagesQuery>(graphql`
        query ComponentImages {
            allFile(filter: {absolutePath: {regex: "/src/assets/components/"}}) {
                edges {
                    node {
                        relativePath
                        publicURL
                        name
                    }
                }
            }
        }
    `)

    return data.allFile.edges.map((edge) => ({
        path: edge.node.relativePath,
        url: edge.node.publicURL,
        name: edge.node.name
    }))
}

export default useComponentImages
