import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'

import type {ComponentsQuery} from '@/graphql-types'
import {sort} from '@/utilities/sort'

import {Card} from './card'
import {Grid} from './list-grid'
import usePatternsImages from './use-patterns-images'

type Pattern = {
    path: string
    category: string
    title: string
    intro: string
}

const IllustrationImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
`

const usePatterns = () => {
    const data = useStaticQuery<ComponentsQuery>(graphql`
        query Patterns {
            components: allSitePage(filter: {context: {frontmatter: {section: {eq: "Patterns"}}}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            category
                            intro
                            tags
                        }
                    }
                }
            }
        }
    `)

    const patterns = data.components.nodes
        .map((guideline) => {
            const meta = guideline.context.frontmatter
            return {
                path: guideline.path,
                title: meta.title,
                category: meta.category,
                intro: meta.intro,
                tags: meta.tags
            }
        })
        .filter((c) => c.path.match('.about') && !c.path.match('.overview'))

    return patterns.sort(sort((c) => c.title.toLowerCase()))
}

const List = ({patterns}: {patterns: Pattern[]}) => {
    const images = usePatternsImages()
    return (
        <Grid>
            {patterns.map(({path, title, intro}) => {
                const image = images[title.toLowerCase()]
                return (
                    <Card
                        key={path}
                        href={path}
                        title={title}
                        illustration={
                            <Card.Illustration>
                                <IllustrationImage alt={`${title} preview`} src={image} />
                            </Card.Illustration>
                        }
                        description={intro}
                    />
                )
            })}
        </Grid>
    )
}

export const PatternsList = () => {
    const patterns = usePatterns()
    return <List patterns={patterns} />
}
