// Based on https://usehooks.com/useLocalStorage/
import {useState} from 'react'

export function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }
        try {
            const item = window.localStorage.getItem(key)

            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
            return initialValue
        }
    })

    const setValue = (value: T) => {
        try {
            setStoredValue(value)

            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(value))
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }
    return [storedValue, setValue] as const
}
