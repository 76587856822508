import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import border from '@pleo-io/telescope-tokens/dist/border.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/color-scheme-light.json'

import {
    Table,
    TableBody,
    TableBodyData,
    TableBodyRow,
    TableHead,
    TableHeadData,
    TableHeadRow,
    TokenName
} from '@/components/docs/table'

const borderTokens = [
    ...colorSchemeLight.props.filter((token) => token.category === 'border'),
    ...border.props
]

const Example = styled.div<{border: string}>`
    height: ${tokens.spacing20};
    border: ${(props) => props.border};
    border-radius: ${tokens.arc4};
`

export const Border = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {borderTokens.map((token) => (
                    <TableBodyRow key={token.name}>
                        <TableBodyData>
                            <TokenName {...token} />
                        </TableBodyData>
                        <TableBodyData>{token.value}</TableBodyData>
                        <TableBodyData>
                            <Example border={token.value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Border
