import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import {Check, Close} from '@pleo-io/telescope-icons'

const Icon = styled.div`
    position: absolute;
    top: ${tokens.spacing12};
    left: ${tokens.spacing12};
    display: flex;
    gap: ${tokens.spacing6};
    align-items: center;
`

const Example = styled.div<{
    $color: string
    $backgroundColor: string
    $borderColor: string
}>`
    position: relative;
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-left: ${tokens.sizeBorderThick} solid ${({$color}) => $color};

    & + & {
        margin-top: ${tokens.spacing18};
    }

    ${Icon} svg {
        padding: ${tokens.spacing4};
        color: ${({$color}) => $color};
        background-color: ${({$backgroundColor}) => $backgroundColor};
        border: ${tokens.sizeBorderDefault} solid ${({$borderColor}) => $borderColor};
        border-radius: ${tokens.arc99999};
    }
`

export const DontExample = ({content}: {content: any}) => (
    <Example $color={tokens.red800} $backgroundColor={tokens.red100} $borderColor={tokens.red800}>
        <Icon>
            <Close />
        </Icon>
        {content}
    </Example>
)

export const DoExample = ({content}: {content: any}) => (
    <Example
        $color={tokens.green800}
        $backgroundColor={tokens.green100}
        $borderColor={tokens.green800}
    >
        <Icon>
            <Check />
        </Icon>
        {content}
    </Example>
)
