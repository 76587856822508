import {graphql, useStaticQuery} from 'gatsby'
import {useState} from 'react'
import styled from 'styled-components'

import type {BadgeVariants, SelectTypes} from '@pleo-io/telescope'
import {Badge, Inline, px, Select, Text, tokens} from '@pleo-io/telescope'

import type {ComponentsQuery} from '@/graphql-types'
import useFuse from '@/hooks/use-fuse'
import {sort} from '@/utilities/sort'

import {Card} from './card'
import {Grid} from './list-grid'
import {SearchInput} from './search-input'
import useComponentImages from './use-components-images'

import {isMobilePage, usePlatform} from '../../hooks/use-platform'

type ComponentStatus = 'new' | 'ready' | 'planned' | 'draft' | 'lab'

type Component = {
    path: string
    status: ComponentStatus
    category: string
    title: string
    intro: string
}

const CategorySelect = styled(Select)`
    width: ${px(100)};
`

const Header = styled(Inline).attrs({space: 12})`
    padding-bottom: ${tokens.spacing36};
    background-color: ${tokens.colorBackgroundStaticPage};

    > :first-child {
        flex: 1;
    }

    > :last-child {
        width: ${px(240)};
    }
`

const Illustration = styled(Card.Illustration)`
    filter: grayscale(100%);
`

const IllustrationInner = styled.div``

const IllustrationImage = styled.img`
    margin: 0 auto;
`

export const useComponents = () => {
    const data = useStaticQuery<ComponentsQuery>(graphql`
        query Components {
            components: allSitePage(
                filter: {context: {frontmatter: {section: {eq: "Components"}}}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            status
                            category
                            intro
                            tags
                        }
                    }
                }
            }
        }
    `)

    const components = data.components.nodes
        .map((component) => {
            const meta = component.context.frontmatter
            return {
                path: component.path,
                title: meta.title,
                status: meta.status as ComponentStatus,
                category: meta.category,
                intro: meta.intro,
                tags: meta.tags
            }
        })
        .filter((c) => c.path.match('.guidelines'))

    return components.sort(sort((c) => c.title.toLowerCase()))
}

const statusToBadgeVariant: Record<ComponentStatus, BadgeVariants> = {
    new: 'discover',
    ready: 'info',
    draft: 'warning',
    planned: 'neutral',
    lab: 'warning'
}

export const statusToBadgeText: Record<ComponentStatus, string> = {
    new: 'New',
    ready: 'Ready',
    draft: 'Draft',
    planned: 'Planned',
    lab: 'Lab'
}

const List = ({components, platform}: {components: Component[]; platform: 'web' | 'mobile'}) => {
    const images = useComponentImages()
    const filtered = platform === 'mobile' ? images.filter((i) => isMobilePage(i.path)) : images

    return (
        <Grid>
            {components.map(({path, title, status, intro}) => {
                const image = filtered.find((i) => i.name === title.toLowerCase())

                return (
                    <Card
                        key={path}
                        href={path}
                        title={title}
                        titleBadge={
                            !status || status === 'ready' ? null : (
                                <Badge variant={statusToBadgeVariant[status]}>
                                    {statusToBadgeText[status]}
                                </Badge>
                            )
                        }
                        illustration={
                            <Illustration>
                                <IllustrationInner>
                                    {image?.url ? (
                                        <IllustrationImage
                                            alt={`${title} preview`}
                                            src={image.url}
                                        />
                                    ) : (
                                        <Text>No image available...</Text>
                                    )}
                                </IllustrationInner>
                            </Illustration>
                        }
                        description={intro}
                    />
                )
            })}
        </Grid>
    )
}

const categories = [
    {label: 'Actions', value: 'actions'},
    {label: 'Form elements', value: 'form-elements'},
    {label: 'Filtering', value: 'filtering'},
    {label: 'Text and lists', value: 'data-display'},
    {label: 'Navigation', value: 'navigation'},
    {label: 'Layout', value: 'layout'},
    {label: 'Feedback and indicators', value: 'indicators'},
    {label: 'Utilities', value: 'utilities'}
]

export const ComponentsList = () => {
    const platform = usePlatform()
    const components = useComponents()
    const platformComponents = components.filter((c) => {
        if (platform === 'mobile') {
            return isMobilePage(c.path)
        }

        return !isMobilePage(c.path)
    })
    const [category, setCategory] = useState<string | undefined>()
    const {result, search, term} = useFuse<Component>(platformComponents, {
        keys: ['title', 'category', 'tags']
    })

    const filteredComponents = !category ? result : result.filter((c) => c.category === category)

    return (
        <div>
            <Header>
                <SearchInput
                    value={term}
                    onChange={search}
                    placeholder="Search components..."
                    name="search-components"
                />
                <CategorySelect
                    isClearable
                    name="basic"
                    placeholder="Choose category..."
                    value={categories.find((c) => c.value === category)}
                    options={categories}
                    onChange={(value) => setCategory((value as SelectTypes.Option)?.value)}
                />
            </Header>
            <List components={filteredComponents} platform={platform} />
        </div>
    )
}
