// @ts-expect-error Tried some of the things here: https://github.com/gatsbyjs/gatsby/issues/29964. No luck.
import {useLocation} from '@reach/router'
import copyToClipboard from 'copy-to-clipboard'
import {navigate} from 'gatsby'
import type React from 'react'
import {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import styled from 'styled-components'

import {Inline, NakedButton, px, tokens} from '@pleo-io/telescope'
import {Link as LinkIcon} from '@pleo-io/telescope-icons'
import {Copy} from '@pleo-io/telescope-icons'

export const useCardStatic = <T extends {displayName: string}>({list}: {list: T[]}) => {
    const location = useLocation()

    const [filterValue, setFilterValue] = useState('')

    const handleFilterChange = (newValue: string) => {
        setFilterValue(newValue)
        navigate(`${location.pathname}#${newValue}`)
    }

    useEffect(() => {
        const hash = location.hash.replace(/^#/, '') ?? ''
        if (hash) {
            setFilterValue(hash)
        } else {
            setFilterValue('')
        }
    }, [location.hash])

    const filteredList = list
        .filter(({displayName}) => !displayName.match('indexesm'))
        .filter(({displayName}) => {
            if (filterValue) {
                return displayName.toLowerCase().includes(filterValue.toLowerCase())
            }
            return true
        })

    return {filterValue, handleFilterChange, location, filteredList}
}

export const CardStatic = ({
    children,
    paddingBlock
}: {
    children: React.ReactNode
    paddingBlock?: string
}) => <CardStaticWrapper $paddingBlock={paddingBlock}>{children}</CardStaticWrapper>

const CardStaticWrapper = styled.li<{$paddingBlock?: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-inline: ${tokens.spacing24};
    padding-block: ${({$paddingBlock}) => $paddingBlock || tokens.spacing24};
    color: ${tokens.colorContentStatic};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
    gap: ${tokens.spacing18};
`

const CardName = styled(NakedButton)`
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};
    /* to match icon size */
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    line-height: ${px(24)};
    transition: ${tokens.motionWithinSmallShort};

    &:hover,
    &:focus {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const StyledLinkIcon = styled(LinkIcon).attrs({size: 24})`
    opacity: 0;
    position: absolute;
    right: 100%;
    transition: ${tokens.motionWithinSmallShort};

    ${CardStaticWrapper}:hover &,
    ${CardName}:focus & {
        margin-right: ${tokens.spacing4};
        opacity: 1;
    }
`

const CardStaticName = ({
    children,
    location
}: {
    children: string
    location: {pathname: string; host: string}
}) => (
    <CardName
        css={{cursor: 'pointer', position: 'relative'}}
        onClick={() => {
            const newPath = `${location.pathname}#${children}`
            navigate(newPath)
            copyToClipboard(`${location.host}${newPath}`)
        }}
    >
        {children}
        <StyledLinkIcon />
    </CardName>
)

const CardStaticAsset = styled(Inline).attrs({space: 12})<{maxHeight?: number}>`
    display: flex;
    flex: 0 0 33.3333%;
    align-items: center;
    justify-content: center;

    svg {
        max-height: ${({maxHeight}) => px(maxHeight || 200)};
    }
`

const StyledCopyButton = styled(NakedButton)<{$didCopy: boolean}>`
    display: flex;
    position: absolute;
    right: ${tokens.spacing12};
    top: ${tokens.spacing12};
    z-index: 1;
    gap: ${tokens.spacing4};
    background: ${tokens.colorBackgroundInteractiveLouder};
    padding: ${tokens.spacing2} ${tokens.spacing6};
    border-radius: ${tokens.arc4};
    align-items: center;
    justify-content: center;
    font-size: ${tokens.fontMedium};
    transition: ${tokens.motionWithinSmallShort};
    opacity: 0;
    transform: scale(0.97);
    cursor: ${({$didCopy}) => ($didCopy ? 'default' : 'pointer')};

    &:hover {
        background: ${tokens.colorBackgroundInteractiveHover};
    }

    &:focus,
    ${CardStaticWrapper}:hover & {
        opacity: 1;
        transform: scale(1);
    }
`

const CardStaticCopyButton = ({text}: {text: string}) => {
    const [didCopy, setDidCopy] = useState(false)

    const onCopy = () => {
        setDidCopy(true)
        setTimeout(() => setDidCopy(false), 1000)
    }
    return (
        <CopyToClipboard text={text} onCopy={onCopy}>
            <StyledCopyButton $didCopy={didCopy}>
                {didCopy ? 'You got it!' : 'Get code'}
                <Copy size={16} />
            </StyledCopyButton>
        </CopyToClipboard>
    )
}

CardStatic.Asset = CardStaticAsset
CardStatic.Name = CardStaticName
CardStatic.CopyButton = CardStaticCopyButton
