module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-mdx@2.6.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_react@18.2.0__gatsby@3.15.0_@_dmyfge6qd6begbhw4szcnx27gq/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/telescope/telescope/telescope/docs"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@5.8.0_babel-plugin-styled-components@2.1.4_@babel+core@7.21.0_qb3u4as72a26hskfkqhmuqkwea/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
